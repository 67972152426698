import React from 'react'
import styles from './FancyHR.module.scss';

const FancyHR = () => {
    return (
        <hr className={styles.accessory} />
    );
};

export default FancyHR
